import { forwardRef, useEffect, useState } from 'react';
import Page from '../components/Page';
import { Container, Stack, Typography, FormControl, Select, MenuItem, InputLabel, CardActionArea, TextField, Checkbox, Button, InputAdornment, IconButton, Popover, Input, Autocomplete } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import configData from "../config.json";
import { fetchUtils } from 'react-admin';
import jsonServerProvider from '../data_provider';
import { useParams } from 'react-router';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import InfoIcon from '@mui/icons-material/InfoRounded';
import FormLabel from '@mui/material/FormLabel';
import ObjectTable from './objecttable';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import HPAY_TRANSLATION from "../i18n_translation";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const md5 = require('md5');


const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function WEditor(params) {
  
  const html2xhtml = (html) => {
	try{
		if(!window.__html2xhtmlDOMParser){
			window.__html2xhtmlDOMParser = new DOMParser();
			window.__html2xhtmlXMLSerializer = new XMLSerializer();
		}
	
		var doc = window.__html2xhtmlDOMParser.parseFromString(html, 'text/html');
		return window.__html2xhtmlXMLSerializer.serializeToString(doc.body).replace(/<\/?body[^>]*>/gi,'');
	}catch(ex){
		return html;
	}	
  };
  
  if(!window.__editors_content){
	  window.__editors_content = {};
  }
  
  if(!window.__editors_content_html){
	  window.__editors_content_html = {};
  }
  
  if(!window.__editors_content[params.editorIndentifier]){
	  window.__editors_content[params.editorIndentifier] = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML( params.HTMLValue || "")));
	  window.__editors_content_html[params.editorIndentifier] = params.HTMLValue;
  }
  
  const [editorState, setEditorState] = useState(window.__editors_content);
  const emptyIfNull = (val) => {
		if(val === null || val === undefined)
			return "";
		return val;
  };
  
  const _onEditorStateChange = function(state){
	  window.__editors_content[params.editorIndentifier] = state;
	  setEditorState(window.__editors_content);
  };
  
  const parametarName = params.parametarName;

  return <Editor 
				editorState={window.__editors_content[params.editorIndentifier]} 
				onEditorStateChange={_onEditorStateChange}
				wrapperClassName="wrapper-class"
				editorClassName="editor-class"
				toolbarClassName="toolbar-class"
				key={"w_" + params.editorIndentifier}
				required={params.required}
				placeholder={params.placeholder}
				defaultEditorState={EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('')))}
				onChange={(...args) => { 
					let html = draftToHtml(convertToRaw(editorState[params.editorIndentifier].getCurrentContent()));
					html = html2xhtml(html);
					
					if(window.__editors_content_html[params.editorIndentifier] != html){
						window.__editors_content_html[params.editorIndentifier] = html;
						args[0].html_changed = true;
					}
					
					args[0].html = html;
					params.onChange(...args);
				}}
				toolbar={{
					inline: { inDropdown: true },
					list: { inDropdown: true },
					textAlign: { inDropdown: true },
					link: { inDropdown: true },
					history: { inDropdown: true },
				}}
				/>;
}

export default function SiteDetails() {
	const moduleScope = "SiteDetails";
    const history = useHistory();
    const params = useParams();
    // eslint-disable-next-line
    const [siteData, setSiteData] = useState({});
    const [UpdateId, setUpdateId] = useState("");
    const [Url, setUrl] = useState("");
    const [Urls, setUrls] = useState("");
	const [SiteEnabled, setSiteEnabled] = useState(false);
	const [MerchantsiteUid, setMerchantsiteUid] = useState("");
    const [CompanyName, setCompanyName] = useState("");
	
	const [showSMTPPassword, setShowSMTPPassword] = useState(false);
	
	
	const [CustomerServiceEmail, setCustomerServiceEmail] = useState("");
	const [CustomerServiceTel, setCustomerServiceTel] = useState("");
	const [CustomerServiceNotes, setCustomerServiceNotes] = useState("");
	
	
	const [TermsLink, setTermsLink] = useState("");
	const [DefaultNotifyUrl, setDefaultNotifyUrl] = useState("");
	
	// eslint-disable-next-line
    
	const [Data, setData] = useState({});
	const [UseKey, setUseKey] = useState("");
		
    const [SiteKey, setSiteKey] = useState("");
	const [SiteType, setSiteType] = useState("site");
	const [SiteSubtype, setSiteSubtype] = useState("standard");
	
    //const token = JSON.parse(sessionStorage.AccessToken).Token;
    const [AlertMessage, setAlertMessage] = useState("success");
    const [AlertType, setAlertType] = useState("success");
    const [AlertOpen, setAlertOpen] = useState(false);
	
	if(!window._subtypeParameters)
		window._subtypeParameters = [];
	
	if(!window._subtypeParametersData)
		window._subtypeParametersData = {};
	
	const [subtypeParameters, setSubtypeParameters] = useState([]);
	const [subtypeParametersData, setSubtypeParametersData] = useState({});
	
	if(!window._subtypeParameters)
		window._subtypeParameters = [];
	
	if(!window._subtypeParametersData)
		window._subtypeParametersData = {};
	
	const _setSubtypeParameters = (parameters) => {
		window._subtypeParameters = parameters || [];
		let data = {};
		window._subtypeParameters.forEach(p => {
			if(window._subtypeParametersData.hasOwnProperty(p.Name)){
				data[p.Name] = window._subtypeParametersData[p.Name];
			}else{
				if(p.hasOwnProperty("Default"))
					data[p.Name] = p.Default;
				else
					data[p.Name] = null;
			}
		});
		window._subtypeParametersData = data;
		
		setSubtypeParameters(window._subtypeParameters);
		setSubtypeParametersData(window._subtypeParametersData);
	};
	
	const _setSubtypeParametersData = (parameters_data) => {
		if(!parameters_data)
			parameters_data = {};
		
		window._subtypeParameters.forEach(p => {
			if(parameters_data.hasOwnProperty(p.Name)){
				window._subtypeParametersData[p.Name] = parameters_data[p.Name];
			}
		});
	};
	
	const setParameterValue = (name, value) => {
		let param = window._subtypeParameters?.find(p => p.Name == name);
		if(param){
			if(param.ValueFilter){
				value = param.ValueFilter(value,window._subtypeParametersData,window._subtypeParametersData);
			}
			window._subtypeParametersData[name] = value;
			setSubtypeParametersData(Object.assign({},window._subtypeParametersData));
		}
	};
	
    const { t } = useTranslation();
	
	const [__refreshView, __setRefreshView] = useState(0);
	
	const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
	
	const refreshView = () => {
		__setRefreshView(Math.random());
	};
	
	const [iSsaving, setIsSaving]  = useState(false);
	
	const isSandbox = configData.API_URL.indexOf("sandbox") > -1;

    const AlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };
	
	const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json', xcompanyid: sessionStorage.company_id, xsiteid: sessionStorage.site_id  });
        }
		const token = JSON.parse(sessionStorage.AccessToken).Token;
        options.headers.set('Authorization', `${token}`);
        return fetchUtils.fetchJson(url, options);
    };
	
	const validateEmail = (email) => {
	  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase());
	};
	
	const handleSiteSubtypeChange = (arg) => {
	  let subtype = arg.selectedValue || arg || "standard";
	  setSiteSubtype(subtype);
	  
	  let ptype = (window._HPAY_POS_TYPES || []).find(pt => pt.Name == SiteType + "-" + subtype);
	  
	  if(ptype){
			_setSubtypeParameters(ptype.Data?.parameters || []);
			_setSubtypeParametersData({});
	  }else{
			_setSubtypeParameters([]);
			_setSubtypeParametersData({});	
	  }
	}; 
	
	const renderPropHTML = (prop, value) => {
		try{
			if(prop.render && typeof prop.render === 'function'){
				return prop.render(prop, value);
			}
			return null;
		}catch(ex){
			console.log(ex);
			return null;
		}
	};

	const validateResult = (name, value) => {
		try{
			
			let parm = subtypeParameters.find(p => p.Name == name);
			
			if(parm){
				if(parm.Validator && typeof parm.Validator === 'function'){
					let result = parm.Validator(value,window._subtypeParametersData, window._subtypeParametersData);
					if(result){
						return <p className='validation-error'>{result}</p>;
					}
				}
			}
		}catch(ex){
			return <p className='validation-error'>{t("bad value!")}</p>;
		}
		return null;
	};

	const insertDescription = (parameter) => {
		if(!parameter.Description){
			return null;
		}
		
		let id = "_popover_" + parameter.Name.toLowerCase().replace(/ /g,""); 
		
		let iicon = <InfoIcon onClick={(e) => { setPopoverAnchorEl(e.target); window[id] = true; refreshView(); }} key={"icon_" + id} className={"info-icon"} style={{  fontSize: 18, cursor:"pointer" }}/>
		
		return [
		iicon,
		<Popover
			key={"desc_" + id}
			id={id}
			open={!!window[id]}
			anchorEl={popoverAnchorEl}
			
			onClose={(e) => {window[id] = false; refreshView();} }
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'left',
			}}
		  >
			<Typography className={"desc-popover"} sx={{ p: 2 }}>{t(parameter.Description)}</Typography>
		  </Popover>
		]  
	};
	
	const emptyIfNull = (val) => {
		if(val === null || val === undefined)
			return "";
		return val;
	};
	
	const updateExchangeInfo = function(){
		
		document.getElementById('exchange_info').innerHTML = "";
		let source = Data["ExchanageSource"] || "RSD";
		if(source == "HRK"){
			source = "EUR";
		}
		
		let ts = parseInt((new Date()).getTime()/1000);
		
		["EUR","USD","CHF","GBP"].forEach((from)=> {
			if(from != source){
				try{
					if(localStorage["exchange_" + from + source]){
						let exc = JSON.parse(localStorage["exchange_" + from + source]);
						if(exc.ts + 7200 > ts){
							let er = exc;
							let span = document.createElement("span");
							span.style.fontSize = "70%";
							span.innerHTML = " " + from + "-" + source + ": " + parseFloat(er.rate).toFixed(4);
							document.getElementById('exchange_info').appendChild(span);
							return;
						}
					}
				}catch(ex){
					//
				}
				
				fetch(configData.CLIENT_URL.replace(/\/$/,'') + "/exchangerate?from=" + from + "&to=" + source).then(r=>r.json()).then(er =>{
							localStorage["exchange_" + from + source] = JSON.stringify(er);
							let span = document.createElement("span");
							span.style.fontSize = "70%";
							span.innerHTML = " " + from + "-" + source + ": " + parseFloat(er.rate).toFixed(4);
							document.getElementById('exchange_info').appendChild(span);
						}).catch(err => console.error(err));
			}
		});
	}
	
	const dataProvider = jsonServerProvider(configData.API_URL + 'api', httpClient);
    const load = () => {
		
		if(!window.HPAYCompanies){
			setTimeout(load,1000);
			return;
		}
		
		if(!window._HPAY_POS_TYPES){
		  dataProvider.getList("pos_types",{ pagination: { page: 1, perPage: 9999 }, sort: { field: 'Order', order: 'ASC' }}).then(r => {
			  window._HPAY_POS_TYPES = r?.data || [];
			  for(var i in window._HPAY_POS_TYPES){
				  if(window._HPAY_POS_TYPES.hasOwnProperty(i) && window._HPAY_POS_TYPES[i].Data){
					  for(var prop in window._HPAY_POS_TYPES[i].Data){
						  if(window._HPAY_POS_TYPES[i].Data.hasOwnProperty(prop)){
							  if(prop == "parameters"){
								  for(var index in window._HPAY_POS_TYPES[i].Data.parameters){
									  if(window._HPAY_POS_TYPES[i].Data.parameters.hasOwnProperty(index)){
										  let p = window._HPAY_POS_TYPES[i].Data.parameters[index];
										  for(var pprop in p){
											  if(p.hasOwnProperty(pprop) && typeof p[pprop] === 'string' && /^function/.test(p[pprop])){
												  try{
													  eval('p[pprop] = (' + p[pprop] + ");");
												  }catch(fex){
													  console.error(fex);
												  }
											  }
										  }
										  window._HPAY_POS_TYPES[i].Data.parameters[index] = p;
									  }
								  }
							  }else if(typeof window._HPAY_POS_TYPES[i].Data[prop] === 'string'){
								  if(/^function/.test(window._HPAY_POS_TYPES[i].Data[prop])){
									  try{
										  eval('window._HPAY_POS_TYPES[i][prop] = (' + window._HPAY_POS_TYPES[i].Data[prop] + ");");
										  delete window._HPAY_POS_TYPES[i].Data[prop];
									  }catch(fex){
										  console.error(fex);
									  }
								  }
							  }
						  }
					  }
				  }
			  }
		  }).catch(err => console.error).finally(() => {
			  if(!window._HPAY_POS_TYPES)
				  window._HPAY_POS_TYPES = [];
			  
			  load();
		  });	
		  return;
	    }
		
		const Id = params.id;
		window.__site_details_data = null;
		
		setIsSaving(true);
		dataProvider.getOne('company_sites', { id: Id })
            .then(response => {
				
				setIsSaving(false);
				
                //setDetails(response.data);
                const item = response.data;
				
				setSiteData(item);
				setUpdateId(item.id);
                setUrl(item.Url);
				
				if(sessionStorage.addsite){
					
					item.Enabled = true;
					
					let addsite_host = sessionStorage.addsite.replace(/https?\:\/\//i,"");
					
					if((item.Url || "").indexOf(addsite_host) === -1){
						if((item.Urls || "").indexOf(addsite_host) === -1){
							if(!item.Urls){
								item.Urls = addsite_host;
							}else{
								item.Urls += ("," + addsite_host);
							}
						}	
					}
					
					if(sessionStorage.addsite_notify_url){
						item.DefaultNotifyUrl = sessionStorage.addsite_notify_url;
					}
				}
				
			    setUrls(item.Urls);
				setMerchantsiteUid(item.MerchantsiteUid);
			    setCompanyName(JSON.parse(sessionStorage.CurrentCompany).name);
				let sdata = JSON.parse(item.Data || "{}");
                setData(sdata);
				setUseKey(item.UseKey);
				setSiteType(item.SiteType.replace(/-.*/,''));
				
				
				let fulltype = "";
				
				if(/-/.test(item.SiteType)){
					fulltype = item.SiteType;
					setSiteSubtype(item.SiteType.replace(/^[^\-]*-/,''));
				}else{
					fulltype = item.SiteType + "-standard";
					setSiteSubtype('standard');
				}
				
				let ptype = (window._HPAY_POS_TYPES || []).find(pt => pt.Name == fulltype);
				if(ptype){
					_setSubtypeParameters(ptype.Data?.parameters || []);
					_setSubtypeParametersData(sdata.parameters || {});
				}else{
					_setSubtypeParameters([]);
					_setSubtypeParametersData({});
				}
				
				setSiteEnabled(item.Enabled ? true : false);
				
				setCustomerServiceEmail(item.CustomerServiceEmail);
				setCustomerServiceTel(item.CustomerServiceTel);
				setCustomerServiceNotes(item.CustomerServiceNotes);
				setTermsLink(item.TermsLink);
				setDefaultNotifyUrl(item.DefaultNotifyUrl);
				
				if(item.SecretToken && item.SecretToken.Token){
					setSiteKey(item.SecretToken.Token);
				}
				
				window.__site_details_data = item;
				
				updateExchangeInfo();
		    })
            .catch(error => {
				
				window.__site_details_data = {error: error};
				
				setIsSaving(false);
				
				if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
						let event = new Event("hpay-auth-expired");
						window.dispatchEvent(event);
					}
					
					
				setAlertMessage("Error in data loading!");
                setAlertType("error");
                setAlertOpen(true);	
					
                console.log(error)
            });
    }
	
	const Update = async (params) => {
		Data.ExchanageCorrection = parseFloat(Data.ExchanageCorrection) || 0.00;
		Data.ExchanageSource = Data.ExchanageSource || "RSD";
		setIsSaving(true);
		
		Data.parameters = subtypeParametersData;
		
		let update_data = { 
			Url: Url, 
			Urls: Urls, 
			MerchantsiteUid: MerchantsiteUid,
			Enabled: SiteEnabled,
			CompanyId: sessionStorage.company_id,
			Data: JSON.stringify(Data || {}),
			UseKey: UseKey,
			CustomerServiceEmail,
			CustomerServiceTel,
			CustomerServiceNotes,
			TermsLink,
			DefaultNotifyUrl
		};
		
		update_data.SiteType = SiteType;
		if(SiteSubtype && SiteSubtype != 'standard'){
			update_data.SiteType += ("-" + SiteSubtype);
		}
		
		let ptype = (window._HPAY_POS_TYPES || []).find(s => s.Name == SiteType + "-" + (SiteSubtype || "standard")); 
		if(ptype && ptype.filterUpdateData){
			try{
				let d = ptype.filterUpdateData(update_data);
				if(d){
					if (typeof d?.then === 'function') {
						update_data = await d;	
					}else{
						update_data = d;
					}
				}
			}catch(cex){
				console.error(cex);
			}
		}
		
		if(!update_data){
			setIsSaving(false);
			return;
		}
		
        dataProvider.update('company_sites', { id: UpdateId, data: update_data })
            .then(response => {
				setIsSaving(false);
				const item = response.data;
                
				if(window.HPAYCompanies && window.HPAYCompanies[sessionStorage.company_id] && window.HPAYCompanies[sessionStorage.company_id].HPAYSites){
					window.HPAYCompanies[sessionStorage.company_id].HPAYSites[response.data.id] = Object.assign(window.HPAYCompanies[sessionStorage.company_id].HPAYSites[response.data.id] || {}, response.data);
					
					if(sessionStorage.site_id == UpdateId){
						sessionStorage.CurrentSite = JSON.stringify({ id: response.data.id, name: response.data.Url });
					}
				}
								
				setUrl(item.Url);
                setUrls(item.Urls);
				setMerchantsiteUid(item.MerchantsiteUid);
				setSiteEnabled(item.Enabled ? true : false);
				setUseKey(item.UseKey);
				
				setCustomerServiceEmail(item.CustomerServiceEmail);
				setCustomerServiceTel(item.CustomerServiceTel);
				setCustomerServiceNotes(item.CustomerServiceNotes);
				setTermsLink(item.TermsLink);
				setDefaultNotifyUrl(item.DefaultNotifyUrl);
				
				setSiteData(item);
				
                setAlertMessage(t("POS was updated correctly!"));
                setAlertType("success");
                setAlertOpen(true);
				
				if(window._hkey_updated){
					if(String(window._hkey_updated) != 'empty'){
						if(!item.UseKey){
							setTimeout(function(){
								setAlertMessage(t("HOLEST PURCHASE KEY WAS NOT ACCEPTED!"));
								setAlertType("error");
								setAlertOpen(true);
							},2000);
						}
					}
					
					window._hkey_updated = false;
					if(item.UseKey){
						setTimeout(function(){
							 dataProvider.getOne('company_sites', { id: UpdateId })
								.then(kresp => {
									if(kresp.data){
										setUseKey(kresp.data.UseKey);
										setSiteData(kresp.data);
									}
								}).catch(kerr=>{
									console.log(kerr)
								})
						},3500);
					}
				}
				
				if(sessionStorage.addsite){
					let addsite_host = sessionStorage.addsite.replace(/https?\:\/\//i,"");
					
					if(response.data.SiteType != "site"){
						setAlertMessage(t("POS type is not an site. You can not connect site to different POS types!"));
						setAlertType("error");
						setAlertOpen(true);
					}else if( ((response.data.Url || "") + "," + (response.data.Urls || "")).indexOf(addsite_host) === -1){
						setAlertMessage(t("Site to be connected must be main url or one of auxiliary urls for the selected POS!"));
						setAlertType("error");
						setAlertOpen(true);
					}else{
						sessionStorage.addedsite = JSON.stringify({
							merchant_site_uid: response.data.MerchantsiteUid,
							secret_token: response.data.SecretToken.Token,
							token_user_id: response.data.SecretToken.UserId,
							company_id: response.data.CompanyId,
							site_id: response.data.id,
							main_url: response.data.Url,
							aux_urls:  response.data.Urls || "",
							connect_user_id: JSON.parse(sessionStorage.UserData).id	
						});
					}
				}
		    })
            .catch(error => {
				setIsSaving(false);
				
				if(error && error.body && error.body.error && /^401 /.test(error.body.error)){
						let event = new Event("hpay-auth-expired");
						window.dispatchEvent(event);
					}
					
                setAlertMessage(t("Error in site update request!"));
                setAlertType("error");
                setAlertOpen(true);
            });
    };

    const regenerate = (params) => {
		
		confirmAlert({
		  title: t('Regenerate secret key'),
		  message: t('Please confirm you want to regenerate POS secret key') + " '" + Url + "'." + t('You will lose connection with this POS untill you update secret key with it.') ,
		  buttons: [
			{
			  label: t('Regenerate secret key'),
			  onClick: () => {
				  
				  setIsSaving(true);
				  
				  dataProvider.update('company_sites', { id: UpdateId, data: { RegenerateSecretKey: 1 }}).then(response => {
					setIsSaving(false);
					load();

				  }).catch(error => {
					setIsSaving(false);
					  
					setAlertMessage("Error in secret key regenerate request!");
					setAlertType("error");
					setAlertOpen(true);
				  });
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
    };

    const ItemDelete = () => {
		
		confirmAlert({
		  title: t('Delete site'),
		  message: t('Please confirm you want to delete site') + " '" + Url + "'." + t('You might lose important data releted to this site.') ,
		  buttons: [
			{
			  label: t('Delete'),
			  onClick: () => {
				  
				  setIsSaving(true);
				  dataProvider.delete('company_sites', {
						id: UpdateId
					})
						.then(response => {
							setIsSaving(false);
							let event = new Event("hpay-company-sites-invalidate");
							window.dispatchEvent(event);  
							history.push('/sites');
						})
						.catch(error => {
							setIsSaving(false);
							setAlertMessage("Error in request!");
							setAlertType("error");
							setAlertOpen(true);
						});
			  }
			},
			{
			  label: t('Cancel'),
			  onClick: () => {}
			}
		  ]
		});
    };

    const back = (params) => {
		history.push('/sites')
    };
	
	const onCompanyChange = () => {
	    
		if(!window.__site_details_data){
			setTimeout(onCompanyChange,1000);
			return;
		}		
		
		if(sessionStorage.company_id && window.__site_details_data.CompanyId == sessionStorage.company_id ){
			return;
		}
		
		history.push('/sites')
    }; 

    useEffect(() => {
		
		window.refreshCurrentView = () =>  {refreshView()};
		
		window._subtypeParameters = {};
		window._subtypeParametersData = {};
		window.__editors_content = {};
		
		window.refreshCurrentView = () =>  {refreshView()};
		
        load();
		
		window.addEventListener("hpay-company-changed", onCompanyChange, false);
	
		return () => {
			
			window.removeEventListener("hpay-company-changed", onCompanyChange, false);
			
		};
	
        // eslint-disable-next-line
    }, [])
    return (
        <Page title="Site Details | Holest" data-view-generation={__refreshView}>
            <Snackbar open={AlertOpen} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={AlertClose}>
                <Alert onClose={AlertClose} severity={AlertType} sx={{ width: '100%' }}>
                    {AlertMessage}
                </Alert>
            </Snackbar>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} >
                    <Typography variant="h4" gutterBottom id="site_type_bar">
                        {t("POS Configuration")} | {t("type")}: {SiteType}
						
						<Select
							labelId="post_subtype_label"
							id="post_subtype"
							value={SiteSubtype}
							label="/"
							onChange={(e) => handleSiteSubtypeChange({selectedValue: e.target.value })}
						>
						{
							((window._HPAY_POS_TYPES || []).find(pt => pt.Name == SiteType + "-standard") ? [] : [{Name:"standard", Title: t("Standard")}]).concat((window._HPAY_POS_TYPES || []).filter(s => s.Name.indexOf(SiteType) > -1)).map((sub_item, sub_key) => {
								return (
									<MenuItem key={sub_item.Name} value={sub_item.Name.replace(/^[^\-]*-/,'')}>{t(sub_item.Title)}</MenuItem>
								)
							})
							
						}
						</Select>
						
                    </Typography>
					
                    {(!sessionStorage.windowedby || sessionStorage.addsite) ? <Button
                        variant="dark"
                        onClick={(e) => back()}
                        startIcon={<ExitToAppIcon />}
                        color="secondary"
                    >
                        {t("Back to sites list...")}
                    </Button> : null}
					
                </Stack>
            </Container>
            <Grid container justifyContent="center">
                <Grid item xs={9}>
                    <Card>
                        <CardActionArea>
                            <CardContent  id="site_name_bar">
                                <Typography variant="p" gutterBottom>
								*** {t("COMPANY")}: {CompanyName} ***
								</Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardContent>
                            <Stack spacing={3}>
                               <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={0}>
									<FormControl component="fieldset" key={"SiteEnabled"}>
                                        <FormControlLabel disabled={sessionStorage.CurrentUserRole == "staff" || sessionStorage.addsite} key={"SiteEnabled"} value={1} onChange={(e) => setSiteEnabled(e.target.checked ? true : false)} required={true} control={<Checkbox color="secondary" checked={SiteEnabled} />} label={t("Enabled")} />
                                    </FormControl>
								</Stack> 
								
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={1} id="site_url_bar">
                                    <TextField
										id="main_pos_uri"
                                        fullWidth
                                        label={t("URI/Indentifier")}
                                        value={Url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
									
                                   
                                </Stack>
								
								{ SiteType == "site" ?
								<Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} paddingTop={0}>
                                    <Typography style={{color:"#637381", fontSize:"80%"}} spacing={0} paddingTop={0}>{t("Additional Site Url-s (one per line)")}:</Typography>
									<textarea
										rows={4}
										id="additional_pos_uris"
                                        placeholder={t("additional site url-s (one per line)")}
                                        value={(Urls || "").split(',').map(s=>s.trim()).join("\n")}
                                        onInput={(e) => {
											
											let urlslist = e.target.value.split('\n').map(s => s.trim().replace(/^https?\:\/\//,'').split("?")[0]).filter(u => u.trim());
											let uniqueItems = [...new Set(urlslist)]
											setUrls(uniqueItems.join(","));
											
										}}
										style={{width:"100%", padding: '8px 12px', borderColor:"#d9d9d9", marginTop:0}}
                                    />
                                </Stack> : null}
								
								<Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} paddingTop={5}>
                                    <TextField
									    InputProps={{style:{color:'#665b5b'}}}
										readOnly={true}
                                        fullWidth
										id="merchant_pos_uid"
                                        label={t("Merchant POS UID")}
                                        value={MerchantsiteUid}
										/*onChange={(e) => setMerchantsiteUid(e.target.value)}*/
                                    />
								</Stack>	
								
								
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} paddingTop={0}>	
									<TextField
									    InputProps={{style:{color:'#665b5b'}}}
										readOnly={true}
                                        fullWidth
										id="pos_secret_key"
                                        label={t("POS Secret Key")}
                                        value={SiteKey || ""}
                                    />
									
									{sessionStorage.CurrentUserRole != "staff" ?
                                    <LoadingButton
                                        fullWidth
                                        size="large"
										loading={iSsaving}
                                        type="submit"
                                        endIcon={<RefreshRoundedIcon />}
                                        variant="contained"
                                        onClick={(e) => regenerate()}
                                        color="secondary"
                                    >
                                        {t("Re-generate")}
                                    </LoadingButton> : null}
                                </Stack>
								
								<Stack direction={{ xs: 'column', sm: 'column' }} spacing={2} paddingTop={1}>
                                    <TextField
                                        fullWidth
										id="default_notify_url"
                                        label={"I(P|S|F|I)N - " + t("Instant payment/shipping/fiscal/integation notification url")}
                                        value={DefaultNotifyUrl || ""}
                                        onInput={(e) => setDefaultNotifyUrl(e.target.value)}
                                    />
							    </Stack>
								
								{
									// eslint-disable-next-line
									subtypeParameters ? subtypeParameters.map((item, key) => {
										
										try{
											
											if(subtypeParameters.length && item.init && !item.__init_done){
												try{
													item.init();
													item.__init_done = true;
												}catch(ex){
													//console.error(ex);
												}
											}
								  
											if (item.Type === "Text") {
												return (
												
													<FormControl style={ (item.Hide || item.InputType == "hidden") ? {display: "none"} : {} }  key={key}>
													{insertDescription(item)}	
														<TextField
															id={"param_" + item.Name.toLowerCase().replace(/ /g,"")}
															fullWidth
															label={t(item.Name)}
															type={item.InputType || "text"}
															placeholder={t(item.Hint)}
															//defaultValue={item.Default}
															required={item.Required}
															value={emptyIfNull(subtypeParametersData[item.Name])}
															onInput={(e) => setParameterValue(item.Name,e.target.value)}
															inputProps={item.inputProps || {}}
															multiline={item.Multiline ? true : false}
															minRows={item.MinRows ? item.MinRows : 1}
															maxRows={item.MaxRows ? item.MaxRows : 4}
														/>
													
													{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}	
													</FormControl>
													
													
												)
											}
											else if (item.Type === "WYSIWYG") {
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
														<FormLabel component="legend" color="secondary">{item.Name}</FormLabel>
														{insertDescription(item)}
														<WEditor
															required={item.Required}
															placeholder={t(item.Hint)}
															currentLocalization={sessionStorage.language}
															editorIndentifier={"editor_" + MerchantsiteUid + "_" +  moduleScope + "_" + item.Name} 
															HTMLValue={emptyIfNull(subtypeParametersData[item.Name])}
															onChange={(e) =>{ 
																setParameterValue(item.Name,e.html || e.blocks[0].text)
															}}
														/>
														
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}	
													</FormControl>
												)
											}
											else if (item.Type === "Upload") {
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
														{insertDescription(item)}
														<FormLabel component="legend" color="secondary">{t(item.Name)} {t(item.Hint)}</FormLabel>
														<label htmlFor={"param_" + item.Name.toLowerCase().replace(/ /g,"")}>
														{emptyIfNull(subtypeParametersData[item.Name]) ?
															<p>{emptyIfNull(subtypeParametersData[item.Name])}<Button onClick={(e) => {
																e.preventDefault();
																//e.stopImmediatePropagation();
																
																delete window.__upload_files[item.Name];
																setParameterValue(item.Name,"");
																
														}}>&times;</Button><br/></p>: null}
														
															<input id={"param_" + item.Name.toLowerCase().replace(/ /g,"")} accept={ item.Accepts ? item.Accepts : ".png,.jpg,.gif" } type="file" style={{ display: "none" }} onChange={(e) => {
																
																let files = [];
																for(let ind in e.target.files){
																	if(e.target.files.hasOwnProperty(ind)){
																		files.push(e.target.files[ind]);
																		break;
																	}
																}
																if(files.length)
																	window.__upload_files[item.Name] = files[0];
																
																setParameterValue(item.Name,files.map(f => f.name).join(", "));
															
															}} />
															<Button variant="contained" color="secondary" required={item.Required} component="span" size="large" fullWidth>
																{item.Name} {t("Upload")}
															</Button>
														</label>
														
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}
													</FormControl>
												)
											}
											else if (item.Type === "Checkbox") {
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  component="fieldset" key={key}>
														{insertDescription(item)}
														<FormControlLabel key={key} value={emptyIfNull(subtypeParametersData[item.Name])} onChange={(e) => setParameterValue(item.Name,e.target.checked)} required={item.Required} control={<Checkbox id={"param_" + item.Name.toLowerCase().replace(/ /g,"")} color="secondary" checked={subtypeParametersData[item.Name] ? true : false} />} label={t(item.Name) + " " + (item.Hint ? ( "(" + t(item.Hint) + ")") : "")} />
														
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}
													</FormControl>
												)
											}
											else if (item.Type === "RADIO") {
												
												
												
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  component="fieldset" key={key}>
														{insertDescription(item)}
														<FormLabel component="legend" color="secondary">{t(item.Name)  + " " + (item.Hint ? ( "(" + t(item.Hint) + ")") : "")}</FormLabel>
														<RadioGroup id={"param_" + item.Name.toLowerCase().replace(/ /g,"")} row aria-label="gender"  name="row-radio-buttons-group" value={emptyIfNull(subtypeParametersData[item.Name])} onChange={(e) => setParameterValue(item.Name,e.target.value)}>
															{
																(typeof item.Options === 'function' ? item.Options() : item.Options ).map((sub_item, sub_key) => {
																	return (
																		<FormControlLabel value={Object.values(sub_item)[0]} required={item.Required} key={sub_key} control={<Radio color="secondary" />} label={Object.values(sub_item)[0]} />

																	)
																})
																
															}
														</RadioGroup>
														
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}
													</FormControl>
												)
											}
											else if (item.Type === "Select") {
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
														{insertDescription(item)}
														<InputLabel id={"param_label-" + item.Name.toLowerCase().replace(/ /g,"")}>{t(item.Name) + " " + (item.Hint ? ( "(" + t(item.Hint) + ")") : "")}</InputLabel>
														<Select
															labelId={"param_label-" + item.Name.toLowerCase().replace(/ /g,"")}
															id={"param_" + item.Name.toLowerCase().replace(/ /g,"")}
															label={item.Name}
															required={item.Required}
															value={emptyIfNull(subtypeParametersData[item.Name])}
															onChange={(e) => setParameterValue(item.Name,e.target.value)}
														>
															{
																(typeof item.Options === 'function' ? item.Options() : item.Options ).map((sub_item, sub_key) => {
																	if(sub_item && typeof sub_item === 'object'){
																		if(item.valueProperty && item.labelProperty){
																			return <MenuItem key={sub_key} value={sub_item[item.valueProperty]}>{sub_item[item.labelProperty]}</MenuItem>	
																		}else{
																			return <MenuItem key={sub_key} value={Object.keys(sub_item)[0]}>{Object.values(sub_item)[0]}</MenuItem>	
																		}
																	}else{
																		return <MenuItem key={sub_key} value={sub_item}>{sub_item}</MenuItem>
																	}
																})
															}

														</Select>
														
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}
													</FormControl>
												)
											}else if (item.Type === "Multiselect") {
												
												
												
												let options = (typeof item.Options === 'function' ? item.Options() : item.Options );
												let val = [];
												if(options && options.length && subtypeParametersData[item.Name] && subtypeParametersData[item.Name].length){
													if(item.valueProperty){
														val = options.filter(o => Object.values(subtypeParametersData[item.Name]).indexOf(o[item.valueProperty]) > -1);
													}else{
														if(typeof options[0] === 'object'){
															if(!Array.isArray(subtypeParametersData[item.Name])){
																subtypeParametersData[item.Name] = [subtypeParametersData[item.Name]];
															}
															val = options.filter(o => Object.values(subtypeParametersData[item.Name]).indexOf(Object.keys(o)[0]) > -1);
														}else{
															val = subtypeParametersData[item.Name];
															if(!Array.isArray(val)){
																val = [val];
															}
														}
													}	
												}
												
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
														{insertDescription(item)}
														<Autocomplete
														  id={"param_" + item.Name.toLowerCase().replace(/ /g,"")}
														  sx={{ m: 1, width: 500 }}
														  multiple
														  options={options}
														  getOptionLabel={(option) => option}
														  disableCloseOnSelect
														  required={item.Required}
														  value={ val }
														  onChange={(e, value) => {
															  let v = null;
															  if(!value){
																  setParameterValue(item.Name,[]);
															  }else if(!value.length){
																  setParameterValue(item.Name,[]);
															  }else if(item.valueProperty){
																  setParameterValue(item.Name,value.map(x => emptyIfNull(x[item.valueProperty])));
															  }else{
																  if(typeof value[0] === 'object'){
																	  setParameterValue(item.Name,value.map(x => Object.keys(x)[0]));
																  }else{
																	  setParameterValue(item.Name,value);
																  }
															  }
														  }}
														  getOptionLabel={(option) => { return item.labelProperty ?  option[item.labelProperty] : ( typeof option === 'object' ? Object.values(option)[0] : option ) }}
														  renderInput={(params) => {
															  if(!params.inputProps){
																  params.inputProps = {};
															  }
															  
															  params.inputProps.autoComplete = "new-password";
															  
															  return (
																<TextField
																  {...params}
																  variant="outlined"
																  label={t(item.Name)}
																  placeholder={t(item.Hint) || "..."}
																/>
															  )
														  }}
														/>
														
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}
													</FormControl>
												)
												
											}else if (item.Type === "Checklist") {
												let opt = typeof item.ListOptions === 'function' ? item.ListOptions() : item.ListOptions;
												let val = subtypeParametersData[item.Name] || {};
												
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
														{insertDescription(item)}
														<FormLabel component="legend">{t(item.Name)}</FormLabel>
														<div>
														{
															opt.map(( item_key, item_key_index) => {
																return <FormControlLabel key={item.Name + item_key + item_key_index}
																				control={
																				  <Checkbox key={item.Name + item_key + item_key_index + "checkbox"} checked={val[item_key] ? true : false} onChange={(e) => {
																					  let v = Object.assign({},subtypeParametersData[item.Name] || {});
																					  v[item_key] = e.target.checked ? 1 : 0;
																					  setParameterValue(item.Name,v);
																				  }} name={item.Name + "_" + item_key } />
																				}
																				label={
																					<>
																					<span key={item.Name + item_key + item_key_index + "name"} style={{fontSize:"50%"}}>{t(item_key) + " "}</span> 
																					{item.ListImages ? (item.ListImages[item_key] ? <img key={item.Name + item_key + item_key_index + "image"} src={item.ListImages[item_key].img || item.ListImages[item_key]} style={{maxHeight:"22px"}} /> : null) : null}
																					</>
																					}
																			  />
																
															})
														}
														</div>
														{t(item.Hint)}
														
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}
													</FormControl>
												)	
											}else if (item.Type === "Table") {
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
														<br/>
														{insertDescription(item)}
														<FormLabel component="legend" color="secondary">{t(item.Name)}:</FormLabel>
														
														<ObjectTable
														  PrimaryIdentifier = {item.PrimaryIdentifier}
														  AllowAddRemoveOffset = {item.AllowAddRemoveOffset}
														  Columns = {item.Columns}
														  Data = { subtypeParametersData[item.Name] || item.Default || ((Array.isArray(item.Default) || item.UseArray) ? [] : {})}
														  MaxRows = {item.MaxRows || 9999}
														  onDataChange = {(data) => {
															  let d = Object.assign((Array.isArray(item.Default) || item.UseArray) ? [] : {}, data);
															  setParameterValue(item.Name,d);
														  }}
														>  
														</ObjectTable>
														{t(item.Hint)}
														{item.Image ? <img src={item.Image} style={{maxHeight:"100px"}} /> : null}
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}
													</FormControl>
												)
											}else if (item.Type === "Fixed") {
												return (
													<FormControl style={ item.Hide ? {display: "none"} : {} }  key={key}>
														<br/>
														{insertDescription(item)}
														{t(item.Name)}: 
														<span id={"param_" + item.Name.toLowerCase().replace(/ /g,"")} >{emptyIfNull(subtypeParametersData[item.Name])}</span>
														{item.render ? renderPropHTML(item, subtypeParametersData[item.Name]) : null}
														<br/>
														{t(item.Hint)}
														{item.Image ? <img src={item.Image} style={{maxHeight:"100px"}} /> : null}
														{validateResult(item.Name, emptyIfNull(subtypeParametersData[item.Name]))}
													</FormControl>
												)
											}
										}catch(ex){
											console.error(ex);
											return null;
										}
									}) : ""
								}

								<Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} paddingTop={5}>	
									
									<InputLabel style={{"minWidth":'140px', fontSize:'80%', 'color':'#637381'}} >{t("Prefered exchange rate source")}</InputLabel>
									<Select
									    style={{marginTop:0}}
										fullWidth
										label={t("Prefered exchange rate source")}
										required={false}
										value={Data.ExchanageSource || "RSD"}
										onChange={(e) => {
											
											let d = Object.assign({},Data); 
											d.ExchanageSource = e.target.value;
											setData(d);
											
											updateExchangeInfo();
											
										}}
									>
										<MenuItem key="RSD" value="RSD">NBS - Narodna banka Srbije</MenuItem>
										<MenuItem key="HRK" value="HRK">HNB - Hrvatska narodna banka</MenuItem>
										<MenuItem key="BAM" value="BAM">CBBH - Centralna banka Bosne i Hercegovine</MenuItem>
										<MenuItem key="MKD" value="MKD">НБРМ - Народна банка на Република Северна Македонија</MenuItem>
										<MenuItem key="EUR" value="EUR">ECB - European Central Bank</MenuItem>
									</Select>
									
									<div id="exchange_info">
									</div>
									
									<TextField
									    InputProps={{style:{color:'#665b5b'}}}
										fullWidth
                                        label={t("Exchange rate correction % (middle)")}
                                        value={(Data.ExchanageCorrection === null || Data.ExchanageCorrection === undefined  || Data.ExchanageCorrection === "") ? "0.00" : Data.ExchanageCorrection}
										onChange={(e) => {
												let d = Object.assign({},Data); 
												d.ExchanageCorrection = e.target.value.replace(/[^\d\.]/g,'').replace(/(\d*\.[^\.]*)(\..*)/g,"$1");
												setData(d);
										}}
                                    />
									
									<InputLabel style={{"minWidth":'140px', fontSize:'80%', 'color':'#637381'}} >{t("Fixed language (for customers)")}</InputLabel>
									<Select
									    style={{marginTop:0}}
										fullWidth
										label={t("Fixed language (for customers)")}
										required={false}
										value={Data.FixedLanguage || "NONE"}
										onChange={(e) => {
											
											let d = Object.assign({},Data); 
											d.FixedLanguage = e.target.value;
											if(d.FixedLanguage == "NONE"){
												d.FixedLanguage = "";
											}
											setData(d);
										}}
									>
										<MenuItem key="NONE" value="NONE">--</MenuItem>
										{HPAY_TRANSLATION.languages.map((lng) => (
										<MenuItem
										  key={lng}
										  value={lng}
										>
										  {t("__LANGUAGE__",{lng:lng})}
										</MenuItem>
									  ))}
									</Select>
									
									
									<InputLabel style={{"minWidth":'140px', fontSize:'80%', 'color':'#637381'}} >{t("Payment vault token saving policy (when available)")}</InputLabel>
									<Select
									    style={{marginTop:0}}
										fullWidth
										label={t("Payment vault token saving policy (when available)")}
										required={false}
										value={Data.CofPolicy || "NULL"}
										onChange={(e) => {
											
											let d = Object.assign({},Data); 
											d.CofPolicy = e.target.value;
											if(d.CofPolicy == "NULL"){
												d.CofPolicy = "";
											}
											setData(d);
										}}
									>
										<MenuItem key="NULL" value="NULL">{t("Leave as POS requested")}</MenuItem>
										<MenuItem key="none" value="none">{t("Never save")}</MenuItem>
										<MenuItem key="required" value="required">{t("Force required")}</MenuItem>
									  ))}
									</Select>
									<div id="exchange_info">
									</div>
						        </Stack>
								
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={0}>
									<FormControl component="fieldset" key={"NoHPayNotifications"}>
                                        <FormControlLabel checked={!!(Data || {}).NoHPayNotifications} value={1} onChange={(e) => {
											
											let d = Object.assign({},Data); 
											d.NoHPayNotifications = e.target.checked;
											setData(d);
										
										}} control={<Checkbox  color="secondary"  />} label={t("Disable HPay Notifications")} />
										<span className='note'>*{t("If your site/POS can handle all customer messaging. Note that some messages are required by law!")}</span>
                                    </FormControl>
								</Stack> 
								
								
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={0}>
									<FormControl component="fieldset" key={"UseExternalSMTP"}>
                                        <FormControlLabel checked={!!(Data || {}).UseExternalSMTP} value={1} onChange={(e) => {
											
											let d = Object.assign({},Data); 
											d.UseExternalSMTP = e.target.checked;
											setData(d);
										
										}} control={<Checkbox  color="secondary"  />} label={t("Use your own SMTP to send customer notifications")} />
										<span className='note'>*{t("Enable if you want to deliver HPay customer mails from your SMTP server. If send fails it will revert to HPay SMTP!")}</span>
                                    </FormControl>
									
									<LoadingButton style={ ((Data || {}).UseExternalSMTP) ? {} : {display: "none"} }
											color="secondary"
											loading={iSsaving}
											size="small"
											type="submit"
											variant="contained"
											onClick={(e) => {
												let settings_invalid = false;
												
												if(!Data.SMTPPort){
													Data.SMTPPort = 587;
												}
												
												["SMTPHost","SMTPUsername","SMTPPassword"].forEach(prop => {
													if(!Data[prop]){
														settings_invalid = prop;
													}else if(!String(Data[prop]).trim()){
														settings_invalid = prop;
													}
												});
												
												if(settings_invalid){
													setAlertMessage(t("Enter proper SMTP settings!") + " (" + settings_invalid + ")");
													setAlertType("error");
													setAlertOpen(true);
													return;
												}
												
												window.hpay_confirm_dialog("<p style='padding:8px'>" + t("Send test mail to") + ": <input type='email' style='padding:6px;min-width:240px;'  class='test_mail_receiver' /></p>", null, t("Send"), t("Cancel")).then(send => {
													if(send){
														if(validateEmail( document.querySelector('.test_mail_receiver:last-child').value)){
															let d = {test_address: document.querySelector('.test_mail_receiver:last-child').value};
															for(var prop in Data){
																if(Data.hasOwnProperty(prop)){
																	if(/^SMTP/.test(prop)){
																		d[prop] = Data[prop];
																	}
																}
															}
															
															setIsSaving(true);
															
															httpClient(configData.API_URL + 'testsmtp',{
																method:"POST",
																body:JSON.stringify(d)
															}).then(r => {
																
																setIsSaving(false);
																
																if(r.json){
																	if(r.json.sent){
																		setAlertMessage(t("Test mail was sent however you must confirm successful reception by checking test address inbox!"));
																		setAlertType("success");
																		setAlertOpen(true);
																	}else{
																		setAlertMessage(t("Error in test mail sending!") + " " +  r.body);
																		setAlertType("error");
																		setAlertOpen(true);
																	}
																}else{
																	setAlertMessage(t("Error in test mail sending!"));
																	setAlertType("error");
																	setAlertOpen(true);
																}
															}).catch(err => {
																setIsSaving(false);
																
																setAlertMessage(t("Error in test mail sending!") + " " + (JSON.stringify(err.body)));
																setAlertType("error");
																setAlertOpen(true);
															});
															
														}else{
															setIsSaving(false);
															setAlertMessage(t("Enter proper email address!"));
															setAlertType("error");
															setAlertOpen(true);
														}
													}
												});
											}}
										>
											{t("TEST SMTP SEND")}
									</LoadingButton>
										
								</Stack> 
								
								<Stack style={ ((Data || {}).UseExternalSMTP) ? {} : {display: "none"} } direction={{ xs: 'column', sm: 'column' }} spacing={2} paddingTop={0}>
										<FormControl component="fieldset" key={"SMTPHost"}>
											<TextField
												fullWidth
												label={t("SMTP Host")}
												value={(Data || {}).SMTPHost || ""}
												onInput={(e) => {
													let d = Object.assign({},Data); 
													d.SMTPHost = e.target.value;
													if(!d.SMTPPort){
														d.SMTPPort = 587;
													}
													setData(d);
												}}
											/>
										</FormControl>
										
										<FormControl component="fieldset" key={"SMTPPort"}>
											<TextField
												fullWidth
												label={t("SMTP Port")}
												value={(Data || {}).SMTPPort || "587"}
												onInput={(e) => {
													let d = Object.assign({},Data); 
													d.SMTPPort = e.target.value.replace(/[^\d]/g,'');
													setData(d);
												}}
											/>
										</FormControl>
										
										<FormControl component="fieldset" key={"SMTPUsername"}>
											<TextField
												fullWidth
												label={t("SMTP Username")}
												value={(Data || {}).SMTPUsername || ""}
												onInput={(e) => {
													let d = Object.assign({},Data); 
													d.SMTPUsername = e.target.value.trim();
													setData(d);
												}}
											/>
										</FormControl>
										
										<FormControl component="fieldset" key={"SMTPFromName"}>
											<TextField
												fullWidth
												label={t("SMTP From Name")}
												value={(Data || {}).SMTPFromName || ""}
												onInput={(e) => {
													let d = Object.assign({},Data); 
													d.SMTPFromName = e.target.value.trim();
													setData(d);
												}}
											/>
										</FormControl>
										
										<FormControl component="fieldset" key={"SMTPPassword"}>
											<TextField
												fullWidth
												label={t("SMTP Password")}
												type={showSMTPPassword ? 'text' : 'password'}
												value={(Data || {}).SMTPPassword || ""}
												InputProps={{
												  endAdornment: (
													<InputAdornment position="end">
													  <IconButton tabIndex={-1} onClick={(e) => { setShowSMTPPassword(!showSMTPPassword) }} edge="end">
														 {showSMTPPassword ? <VisibilityOff /> : <Visibility />}
													  </IconButton>
													</InputAdornment>
												  ),
												  autoComplete:"one-time-code"
												}}
												onInput={(e) => {
													let d = Object.assign({},Data); 
													d.SMTPPassword = e.target.value;
													setData(d);
												}}
											/>
										</FormControl>
										
										<FormControl component="fieldset" key={"SMTPSecurity"}>
										<Select
											style={{marginTop:0}}
											fullWidth
											label={t("SMTP Security Protocol")}
											required={false}
											value={(Data || {}).SMTPSecurity || "ssl"}
											onChange={(e) => {
												let d = Object.assign({},Data); 
												d.SMTPSecurity = e.target.value;
												setData(d);
											}}
										>
											<MenuItem key="ssl" value="ssl">{t("SSL")}</MenuItem>
											<MenuItem key="tls" value="tls">{t("TLS")}</MenuItem>
											<MenuItem key="starttls" value="starttls">{t("StartTLS")}</MenuItem>
										  ))}
										</Select>
										
										</FormControl>
										
									</Stack>
								
								<Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} paddingTop={3}>
									<TextField
									    fullWidth
										style={{backgroundColor:"#f2fae1"}}
										required={!isSandbox}
										disabled={isSandbox}
                                        label={t("HOLEST Key (purchase key)")}
									    value={UseKey || ""}
										onChange={(e) => {
											window._hkey_updated = String(e.target.value || "empty").trim();
											setUseKey(String(e.target.value).trim());
										}}
                                    />
									<p style={{fontSize:"70%"}}>{t("Key expires at")}: <span style={{fontWeight: "bold"}}>{siteData.KeyValidTill ? String(siteData.KeyValidTill).substring(0,10) : "--"}</span> | {(isSandbox ? t("not needed for the sandbox mode") : t("key you bought on ecommerce.holest.com"))} | {(siteData.KeyInfo ? (JSON.stringify(siteData.KeyInfo).replace(/\"|\{|\}/g,'').replace(/\,/g,', ').replace(/\:/g,': ')) : "")}</p>
									<p style={{fontSize:"70%", fontStyle:"italic"}}>
										<span style={{color: siteData.POSIsActivated ? "green" : "red", fontWeight: "bold" }} >{t("ACTIVATED") + ": " + (siteData.POSIsActivated ? t("Yes") : t("No"))}</span> 
									  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {t("Subscriptions") + ": " + (siteData.SubscriptionsUnlocked ? t("Yes") : t("No"))} 
									  | {t("Shipping") + ": " + (siteData.ShippingUnlocked ? t("Yes") : t("No"))} 
									  | {t("Fiscal/integration/e-invoice") + ": " + (siteData.FiscalUnlocked ? t("Yes") : t("No"))} 
									   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <a style={{
										fontStyle: "italic",
										textDecoration: "none",
										color: "#2b659a",
										fontWeight:"bold"
									}} target={"_bank"} href={configData.BUY_KEY_URL} >{t("Buy a new key...")}</a>
									</p>
								</Stack>
								
								<hr/>
								
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={1}>
                                    <TextField
                                        fullWidth
										type={"email"}
										id="customer_support_email"
                                        label={t("Customer support email")}
                                        value={CustomerServiceEmail || ""}
                                        onChange={(e) => setCustomerServiceEmail(e.target.value)}
                                    />
							    </Stack>
								
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={1}>
                                    <TextField
                                        fullWidth
										type={"tel"}
										id="customer_support_telephone"
                                        label={t("Customer support telephone")}
                                        value={CustomerServiceTel || ""}
                                        onChange={(e) => setCustomerServiceTel(e.target.value)}
                                    />
							    </Stack>
								
								<Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} paddingTop={0}>
                                    <Typography style={{color:"#637381", fontSize:"80%"}} spacing={0} paddingTop={0}>{t("Customer support notes")}:</Typography>
									<textarea
										rows={4}
                                        placeholder={t("enter notes about your customer support")}
										id="customer_support_notes"
                                        value={CustomerServiceNotes || ""}
                                        onChange={(e) => setCustomerServiceNotes(e.target.value)}
										style={{width:"100%", padding: '8px 12px', borderColor:"#d9d9d9", marginTop:0}}
                                    />
                                </Stack>
								
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={1}>
                                    <TextField
                                        fullWidth
										id="customer_support_terms_link"
                                        label={t("Your payment terms link")}
                                        value={TermsLink || ""}
                                        onChange={(e) => setTermsLink(e.target.value)}
                                    />
							    </Stack>
								

								{sessionStorage.CurrentUserRole != "staff" ?
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingTop={10} id="site_bottom_bar">
                                    <LoadingButton
                                        fullWidth
                                        color="secondary"
										loading={iSsaving}
                                        size="large"
                                        type="submit"
                                        endIcon={<CheckIcon />}
                                        variant="contained"
                                        onClick={(e) => Update()}
                                    >
                                        {t("Save")}{ sessionStorage.addsite ? (" " + t("and choose...")) : null}
                                    </LoadingButton>
									{!sessionStorage.windowedby ?
                                    <LoadingButton
                                        //fullWidth
                                        color="error"
                                        size="large"
										loading={iSsaving}
                                        endIcon={<DeleteForeverIcon />}
                                        type="reset"
                                        variant="contained"
                                        onClick={(e) => ItemDelete()}
                                    >
                                        {t("Delete")}
                                    </LoadingButton>: null}
                                </Stack> : null}

                            </Stack>
                            {/* </Form>
                            </FormikProvider> */}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
}
